import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProductCard from '../ProductCard/ProductCard';
import styled from '@emotion/styled';
import { Box, Stack, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const Homecont = styled(Stack)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const skeletonloading = [1, 2, 3, 4];

// Function to shuffle array
const shuffleArray = (array) => {   
  let shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};

const RecentViews = () => {
  const { category, product_id } = useParams(); // Correctly use 'category' as defined in your route and Extract 'product_id' from the URL
  const parsedCategoryId = category ? parseInt(category, 10) : null; // Safely parse category as a number
  const currentProductId = product_id ? parseInt(product_id, 10) : null; // Safely parse product_id
  //console.log('Extracted category:', category); // Debug: Check extracted category
  //console.log('Parsed categoryId:', parsedCategoryId); // Debug: Check parsed categoryId

  // Fetch products and categories from Redux state
  const Products = useSelector((state) => state.product);
  const { product } = Products.products;

  // const Category = useSelector((state) => state.category);
  // const { category: categories } = Category.category;

  const [shuffledProducts, setShuffledProducts] = useState([]);

  // Define category relationships
  const categoryMapping = {
    1: [1, 2], // Home care includes home care and kitchen care
    2: [1, 2], // Kitchen care includes both as well
    5: [5, 7], // Skin care includes skin and body care
    7: [5, 7], // Body care includes both as well
  };

   // Filter products based on relevant categories
   useEffect(() => {
    if (product && product.length > 0) {
      const relevantCategories = categoryMapping[parsedCategoryId] || [];
      const filteredProducts = product.filter(
        (item) =>
          (relevantCategories.length === 0 || relevantCategories.includes(parseInt(item.category_id))) &&
          parseInt(item.id) !== currentProductId
      );
      setShuffledProducts(shuffleArray(filteredProducts.length ? filteredProducts : product));
    }
  }, [product, parsedCategoryId, currentProductId]); // Depend on category, product, and product_id


  // Determine relevant categories
  // const relevantCategories = categoryMapping[parsedCategoryId] || [];
  // //console.log('Relevant Categories:', relevantCategories); // Debug: Check relevant categories

  // // Filter products based on relevant categories and exclude the current product
  // const filteredProducts = product
  //   ? product.filter(
  //       (item) =>
  //         relevantCategories.includes(parseInt(item.category_id)) &&
  //         parseInt(item.id) !== currentProductId // Exclude the current product
  //     )
  //   : [];
  // //console.log('Filtered Products:', filteredProducts); // Debug: Check filtered products

  // // Shuffle filtered products
  // //const shuffledProducts = shuffleArray(filteredProducts);

  // // If no specific product or category, show random products
  // const randomProducts = product ? shuffleArray(product) : [];

  // // Determine products to display
  // const productsToShow = filteredProducts.length > 0 ? filteredProducts : randomProducts;

  return (
    <Homecont width={'100%'}>
      <Box padding={'20px'} width={'100%'}>
        <Typography
          variant="h3"
          style={{ fontSize: '20px', fontWeight: '600', color: 'black' }}
        >
          You Might Be Interested In
        </Typography>
      </Box>
      <div className="w-full md:w-[100%] flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-0 gap-y-10">
        {Products.loading &&
          skeletonloading.map((items, idx) => (
            <Skeleton
              key={idx.toString()}
              variant="rectangular"
              animation="wave"
              width={232}
              height={270}
            />
          ))}
        {!Products.loading && Products.error ? (
          <div>Error: {Products.error}</div>
        ) : null}
        {!Products.loading && shuffledProducts.length !== 0 ? (
          shuffledProducts.slice(0, 4).map((val, i) => (
            <ProductCard val={val} page={'re'} key={i} />
          ))
        ) : (
          <div
            style={{
              width: '100%',
              height: '70vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <p
              variant="h1"
              style={{ fontSize: '20px', fontWeight: '600', color: 'red' }}
            >
              No Products.
            </p>
          </div>
        )}
      </div>
    </Homecont>
  );
};

export default RecentViews;








/*
OLD Code With all random products from db without category
*/


// import styled from '@emotion/styled';
// import { Box, Stack, Typography } from '@mui/material'
// import React from 'react'
// import "./style.css"
// import { useSelector } from 'react-redux';
// import Skeleton from '@mui/material/Skeleton';
// import ProductCard from '../ProductCard/ProductCard';

// const CarouselCont = styled(Box)`
//     width:85%;
//     height:auto;
//     padding:35px;
//   `
// const Homecont = styled(Stack)`
//   display:flex;
//   flex-direction:column;
//   justify-content:center;
//   align-items:center;
//   padding:10px;
// `

// const skeletonloading = [1, 2, 3, 4];

// const RecentViews = () => {
//     const Products = useSelector((state) => state.product);
//     const { product } = Products.products;
   
//     return (
//         <Homecont width={'100%'}>
//             <Box padding={'20px'} width={'93%'}>
//                 <Typography variant='h3' style={{ fontSize: '20px', fontWeight: '600', color: 'black' }}>You Might Be Interested In</Typography>
//             </Box>
//             <div className='w-full md:w-[90%] flex flex-col md:flex-row flex-wrap justify-center items-center md:justify-start gap-5'>
//                 {Products.loading && skeletonloading.map((items, idx) => { return (<Skeleton key={idx.toString()} variant="rectangular" animation="wave" width={232} height={270} />) })}
//                 {!Products.loading && Products.error ? <div>Error: {Products.error}</div> : null}
//                 {!Products.loading && Products.products.product !== undefined ? (
//                     product.slice(0, 5).map((val, i) => {
//                         return (
//                                 <ProductCard val={val} page={"re"} key={i}/>
//                         )
//                     })
                    
//                 ) : null}
//             </div>
//         </Homecont >
//     )
// }

// export default RecentViews
