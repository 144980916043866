import React, { useEffect, useState } from "react";
import { youraddress, DeleteAddress, updateAddressById } from "../../../../Api/Api";
import { Helmet } from "react-helmet";
import AddAddressModal from "../../Cart/AddAddressModal";
import { toast } from "react-toastify";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const validationSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  house: Yup.string().required('House/Flat/Office is required'),
  phone: Yup.string().required('Phone Number is required').matches(/^[0-9]{10}$/, 'Phone Number must be 10 digits'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  state: Yup.string().required('State is required'),
  pincode: Yup.string().required('Pincode is required').matches(/^[0-9]{6}$/, 'Pincode must be 6 digits'),
  city: Yup.string().required('City is required'),
  area: Yup.string().required('Road Name/Area/Colony is required'),
  delivery_contact: Yup.string().required('Delivery Contact is required'), // Add validation for delivery_contact
});

const YourAddress = () => {
  const [useraddress, setUseraddress] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [showLimitAlert, setShowLimitAlert] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [addressToDelete, setAddressToDelete] = useState(null);

  useEffect(() => {
    const user = sessionStorage.getItem("___user");
    if (user) {
      youraddress({ user_id: user })
        .then((res) => {
          setUseraddress(res);
          console.log(res);
        })
        .catch((error) => {
          console.error("Error fetching addresses:", error);
        });
    }
  }, []);

  const confirmDelete = (id) => {
    setAddressToDelete(id);
    setOpenDeleteDialog(true);
  };

  const deleteClick = () => {
    DeleteAddress(addressToDelete)
      .then(() => {
        toast.success("Delete Success");
        const updatedAddresses = useraddress.filter((address) => address.id !== addressToDelete);
        setUseraddress(updatedAddresses);
        setOpenDeleteDialog(false);
      })
      .catch((error) => {
        console.error("Error deleting address:", error);
        toast.error("Failed to delete address");
        setOpenDeleteDialog(false);
      });
  };

  const handleEdit = (index, profile) => {
    setEditIndex(index);
    setCurrentId(profile.id); // Set the current profile's ID
    setOpenEditDialog(true);
  };

  const handleSave = (values) => {
    updateAddressById({ ...values, id: currentId }) // Include the ID in the API call
      .then((res) => {
        const updatedAddress = [...useraddress];
        updatedAddress[editIndex] = { ...values, id: currentId };
        setUseraddress(updatedAddress);
        setOpenEditDialog(false);
        toast.success("Update success");
        window.location.reload(); // Reload the page
      })
      .catch((error) => {
        console.error("Error updating address:", error);
        toast.error("Failed to update address");
      });
  };

  const handleAddAddress = () => {
    if (useraddress.length <= 6) {
      setShowLimitAlert(true);
    } else {
      // Handle adding address logic here (e.g., open another modal or navigate to a form)
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="flex flex-col items-center justify-center gap-8 mb-[85px]">
        <h1 className="lg:text-4xl md:text-2xl text-2xl font-medium mt-8">Your Addresses</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-[5rem]">
          {useraddress.slice(0, 5).map((profile, index) => (
            <div
              key={profile.id}
              className="bg-white p-6 border border-gray-300 rounded-lg shadow-lg boxe flex flex-col justify-center items-start mt-1 relative"
              style={{ boxShadow: "2px 2px 10px rgba(0, 195, 255, 0.5)" }}
            >
              <div className="box-image">
                <img
                  src={`${process.env.REACT_APP_IMAGE}/icons/Apsensyscare-logo-address.png`}
                  alt=""
                />
              </div>
              <div className="mb-1">
                <strong>Name: </strong>
                {profile.name}
              </div>
              <div className="mb-1">
                <strong>Address: </strong> {profile.house_flat_office}
              </div>
              <div>
                <strong>Contact: </strong> {profile.delivery_contact}
              </div>
              <div>
                <strong>State: </strong> {profile.state}
              </div>
              <div>
                <strong>Pincode: </strong> {profile.pincode}
              </div>
              <div>
                <strong>Email: </strong> {profile.email}
              </div>
              <div className="absolute bottom-4 right-4 flex gap-2">
                <Button
                  color="primary"
                  onClick={() => handleEdit(index, profile)}
                  variant="contained"
                >
                  Edit
                </Button>
                {/* <Button
                  color="error"
                  onClick={() => confirmDelete(profile.id)}
                  variant="outlined"
                >
                  Delete
                </Button> */}
              </div>
            </div>
          ))}
          <div className="w-80 h-80 border-small-line flex flex-col items-center cursor-pointer opacity-50 justify-center rounded-lg">
            <div className="flex flex-col mb-0 items-center">
              {useraddress.length >= 5? (
                <div
                  className="text-[12px] text-darkest mt-0 text-[blueviolet] cursor-pointer"
                  onClick={handleAddAddress}
                >
                  + &nbsp; ADD NEW ADDRESS
                </div>
              ) : (
                <div className="text-2xl font-bold text-darkest mt-0">
                  <AddAddressModal />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Edit Address Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          Edit Address
          <Button
            color="inherit"
            onClick={() => setOpenEditDialog(false)}
            aria-label="close"
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </Button>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: useraddress[editIndex]?.name,
              house: useraddress[editIndex]?.house_flat_office,
              phone: useraddress[editIndex]?.contact,
              email: useraddress[editIndex]?.email,
              state: useraddress[editIndex]?.state,
              pincode: useraddress[editIndex]?.pincode,
              city: useraddress[editIndex]?.city,
              area: useraddress[editIndex]?.area_landmark,
              delivery_contact: useraddress[editIndex]?.delivery_contact || "", // Added delivery_contact field
              address_type: "home",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSave}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="p-4 flex justify-between">
                  <div className="flex"></div>
                  <div></div>
                </div>
                <div className="formAlign">
                  <div style={{ width: "100%", margin: "10px" }}>
                    <Field
                      as={TextField}
                      label="Pincode"
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                      name="pincode"
                      error={!!(errors.pincode && touched.pincode)}
                      helperText={errors.pincode && touched.pincode ? errors.pincode : ''}
                    />
                  </div>
                  <div style={{ width: "100%", margin: "10px" }}>
                    <Field
                      as={TextField}
                      label="City"
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                      name="city"
                      error={!!(errors.city && touched.city)}
                      helperText={errors.city && touched.city ? errors.city : ''}
                    />
                  </div>
                  <div style={{ width: "100%", margin: "10px" }}>
                    <Field
                      as={TextField}
                      label="State"
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                      name="state"
                      error={!!(errors.state && touched.state)}
                      helperText={errors.state && touched.state ? errors.state : ''}
                    />
                  </div>
                </div>
                <div className="formAlign">
                  <div style={{ width: "100%", margin: "10px" }}>
                    <Field
                      as={TextField}
                      label="House/Flat/Office"
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                      name="house"
                      error={!!(errors.house && touched.house)}
                      helperText={errors.house && touched.house ? errors.house : ''}
                    />
                  </div>
                  <div style={{ width: "100%", margin: "10px" }}>
                    <Field
                      as={TextField}
                      label="Road Name/Area/Colony"
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                      name="area"
                      error={!!(errors.area && touched.area)}
                      helperText={errors.area && touched.area ? errors.area : ''}
                    />
                  </div>
                </div>
                <Typography variant="h5" m={2} style={{ fontWeight: "bold" }}>
                  Contact
                </Typography>
                <div className="formAlign">
                  <div style={{ width: "100%", margin: "10px" }}>
                    <Field
                      as={TextField}
                      label="Name"
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                      name="name"
                      error={!!(errors.name && touched.name)}
                      helperText={errors.name && touched.name ? errors.name : ''}
                    />
                  </div>
                  <div style={{ width: "100%", margin: "10px" }}>
                    <Field
                      as={TextField}
                      label="Phone"
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                      name="phone"
                      disabled
                      error={!!(errors.phone && touched.phone)}
                      helperText={errors.phone && touched.phone ? errors.phone : ''}
                      inputProps={{ maxLength: 10 }}
                    />
                  </div>
                  <div style={{ width: "100%", margin: "10px" }}>
                    <Field
                      as={TextField}
                      label="Delivery Contact"
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                      name="delivery_contact"
                      error={!!(errors.delivery_contact && touched.delivery_contact)}
                      helperText={errors.delivery_contact && touched.delivery_contact ? errors.delivery_contact : ''}
                      inputProps={{ maxLength: 10 }}
                    />
                  </div>
                  <div style={{ width: "100%", margin: "10px" }}>
                    <Field
                      as={TextField}
                      label="Email"
                      variant="standard"
                      sx={{ m: 1, width: "100%" }}
                      name="email"
                      error={!!(errors.email && touched.email)}
                      helperText={errors.email && touched.email ? errors.email : ''}
                    />
                  </div>
                </div>
                <div className="formAlign">
                  <Button
                    type="submit"
                    variant="contained"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setOpenEditDialog(false)} color="primary">
            Close
          </Button> */}
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this address?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteClick} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {showLimitAlert && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 max-w-md mx-auto rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-bold mb-4">Address Limit Reached</h2>
            <p className="text-gray-700">
              You can only add up to 6 addresses. Please remove an existing
              address to add a new one.
            </p>
            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md"
              onClick={() => setShowLimitAlert(false)}
            >
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default YourAddress;
